<template>
  <div>
    <AddTrafficRoutingRulesModalComponentV2
      ref="add_traffic_routing_rules_modal_component_v2" :self="this.self"
      :routing_rules="routing_rules"
      :campaign_id="campaign_id"
    ></AddTrafficRoutingRulesModalComponentV2>
    <div :class="{'opacity-30': !getSaveLink.is_routing_rule}" v-if="toggle">
      <p class="text !text-[#202324] mb-[1.25rem] font-bold">Traffic Routing Rules</p>

      <!-- Uncomment this block of code for UI  -->
      <div v-if="false" :key="index" v-for="(ruleList, index) in computeSelectedRules" class="w-full dropdown_header mt-[0.75rem] bg-[#F4F6FA] min-h-[3.5rem] px-3 py-2 rounded-[0.5rem] d-flex align-items-center">
        <div class="w-full flex justify-between items-center">
          <div class=" ">
            <div class="flex items-center justify-start">
            <span class="font-poppins text-[0.75rem] text-[#757A8A]">On specific
               <template v-if="ruleList.rule.length === 1">
                 <span v-tooltip="getSelectedItems(ruleList.rule[0]).toString()" class="font-poppins text-[0.75rem] text-[#2961D2]">{{ ruleList.rule[0].selectedRule.type }}</span>
               </template>
              <template v-else-if="ruleList.rule.length === 2">
                <span v-for="(rule, index) in ruleList.rule" :key="index">
                  <span v-tooltip="getSelectedItems(rule).toString()" class="font-poppins text-[0.75rem] text-[#2961D2]">{{ rule.selectedRule.type }}</span>
                  <span class="font-poppins text-[0.75rem] text-[#757A8A]" v-if="index === 0"> and </span>
                </span>
              </template>
              <template v-else>
                <span v-for="(rule, index) in ruleList.rule" :key="index">
                  <span v-tooltip="getSelectedItems(rule).toString()" class="font-poppins text-[0.75rem] text-[#2961D2]">{{ rule.selectedRule.type }}</span>
                  <span class="font-poppins text-[0.75rem] text-[#757A8A]" v-if="index < ruleList.rule.length - 2">, </span>
                  <span class="font-poppins text-[0.75rem] text-[#757A8A]" v-else-if="index === ruleList.rule.length - 2"> and </span>
              </span>
              </template>
              go to this URL
            </span>
            </div>
            <p class="text !text-[#3C4549] mt-[0.5rem]">{{ ruleList.then }}</p>
          </div>
          <svg @click="routing_rules.splice(index, 1)" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer w-[1rem] h-[1rem]" width="16" height="18" viewBox="0 0 16 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80735 2.02094C5.96582 1.86247 6.18076 1.77344 6.40487 1.77344H9.5949C9.81901 1.77344 10.0339 1.86247 10.1924 2.02094C10.3509 2.17941 10.4399 2.39434 10.4399 2.61845V3.4625H5.55985V2.61845C5.55985 2.39434 5.64888 2.17941 5.80735 2.02094ZM4.7713 4.96249C4.78407 4.96314 4.79692 4.96347 4.80985 4.96347C4.82278 4.96347 4.83564 4.96314 4.8484 4.96249H11.1514C11.1641 4.96314 11.177 4.96347 11.1899 4.96347C11.2028 4.96347 11.2157 4.96314 11.2285 4.96249H12.8324V15.3786C12.8324 15.6027 12.7434 15.8176 12.5849 15.9761C12.4265 16.1346 12.2115 16.2236 11.9874 16.2236H4.01235C3.78823 16.2236 3.5733 16.1346 3.41483 15.9761C3.25636 15.8176 3.16733 15.6027 3.16733 15.3786V4.96249H4.7713ZM4.05985 3.4625V2.61845C4.05985 1.99652 4.30692 1.40005 4.74669 0.960277C5.18647 0.520501 5.78293 0.273438 6.40487 0.273438H9.5949C10.2168 0.273438 10.8133 0.520501 11.2531 0.960277C11.6929 1.40005 11.9399 1.99652 11.9399 2.61845V3.4625H15.1774C15.5916 3.4625 15.9274 3.79828 15.9274 4.2125C15.9274 4.62671 15.5916 4.96249 15.1774 4.96249H14.3324V15.3786C14.3324 16.0005 14.0854 16.597 13.6456 17.0368C13.2058 17.4765 12.6094 17.7236 11.9874 17.7236H4.01235C3.39041 17.7236 2.79394 17.4765 2.35417 17.0368C1.91439 16.597 1.66733 16.0005 1.66733 15.3786V4.96249H0.822266C0.408052 4.96249 0.0722656 4.62671 0.0722656 4.2125C0.0722656 3.79828 0.408052 3.4625 0.822266 3.4625H2.41728H4.05985ZM6.40472 7.45042C6.81894 7.45042 7.15472 7.78621 7.15472 8.20042V12.9855C7.15472 13.3997 6.81894 13.7355 6.40472 13.7355C5.99051 13.7355 5.65472 13.3997 5.65472 12.9855V8.20042C5.65472 7.78621 5.99051 7.45042 6.40472 7.45042ZM10.3449 8.20042C10.3449 7.78621 10.0091 7.45042 9.59485 7.45042C9.18064 7.45042 8.84485 7.78621 8.84485 8.20042V12.9855C8.84485 13.3997 9.18064 13.7355 9.59485 13.7355C10.0091 13.7355 10.3449 13.3997 10.3449 12.9855V8.20042Z" fill="#97304A"/>
          </svg>
        </div>
      </div>

      <hr class="mt-[1.25rem] mb-[1rem]" />
      <button type="button" :disabled="!getSaveLink.is_routing_rule" @click="editRules()" v-if="routing_rules && routing_rules.length" class="text !text-[#2961D2] cursor-pointer font-semibold">{{ routing_rules.length }} Routing Rules<i class="fas fa-pen ml-2"></i></button>
      <button type="button" :disabled="!getSaveLink.is_routing_rule" v-else class="text !text-[#2961D2] cursor-pointer font-semibold" @click="addNewRule()">+ Add New Rule</button>

    </div>
  </div>
</template>
<script>

export default {
  props: [
    'toggle',
    'routing_rules',
    'self',
    'is_ab_test',
    'campaign_id',
    'traffic_routing_cta_failed',
    'traffic_routing_iframe_loader',
    'check_iframe_error'
  ],
  components: {
    AddTrafficRoutingRulesModalComponentV2: () => import('@/views/pages/links/AddTrafficRoutingRulesModalComponentV2.vue')
  },
  computed: {
    /**
     * Compute selected rules
     * @returns {*}
     */
    computeSelectedRules() {
      return this.routing_rules && this.routing_rules.length ? this.routing_rules.map((item) => {
        return {
          rule: item.blocks.flat().map(item => {
            return {
              selectedRule: item.selectedRule,
              key: item.key,
              value: item.value
            }
          }),
          then: item.then
        }
      }) : []
    }
  },
  methods: {
    addNewRule () {
      this.$refs.add_traffic_routing_rules_modal_component_v2.init()
      this.$bvModal.show('addTrafficRoutingRulesModalComponentV2')
      this.$refs.add_traffic_routing_rules_modal_component_v2.iframeURLSObj = {}
    },
    editRules () {
      this.$refs.add_traffic_routing_rules_modal_component_v2.resetTrafficRoutingFilter()
      this.$refs.add_traffic_routing_rules_modal_component_v2.recheckingIframe(this.self)
      this.$refs.add_traffic_routing_rules_modal_component_v2.iframeURLSObj = {}
      this.$bvModal.show('addTrafficRoutingRulesModalComponentV2')
    },
    getSelectedItems (rule) {
      return rule.value.map(item => item.name)
    }
  }
}
</script>
